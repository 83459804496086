<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <v-card>
        <v-card-title>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                      <span class="headline">{{ formTitle }} </span>
                      <v-btn @click="handleCloseModalForm" icon class="close-button">
                        <v-icon> mdi-close</v-icon>
                      </v-btn>
                </v-col>
              </v-row>
        </v-card-title>
        <v-card-text>
              <v-form v-model="formValid" ref="form">
                <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="2" md="2">
                        <v-select
                              v-if="statuses.competition_calendar"
                              v-model="formModel.status"
                              :items="statuses.competition_calendar.competition_calendar"
                              :label="$t('FORMS.status')"
                              item-value="key"
                        >
                              <template v-slot:selection="data">
                                {{ $t("STATUSES." + data.item.value) }}
                              </template>
                              <template v-slot:item="data">
                                {{ $t("STATUSES." + data.item.value) }}
                              </template>
                        </v-select>
                      </v-col>
                </v-row>
                  
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-menu
                            ref="date_of_competition_date_picker"
                            v-model="date_of_competition_date_picker"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formModel.date_of_competition"
                                :rules="requiredRules"
                                label='Verseny időpontja'
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formModel.date_of_competition"
                              no-title
                              scrollable
                              locale="hu-HU"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="date_of_competition_date_picker = false"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col> 
                </v-row>   

                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="formModel.translations[selectedLocale.lang].name"
                              :rules="nameRules"
                              :label="$t('FORMS.name')"
                              :id="dynamicID"
                              :error-messages="
                                messages['translations.' + selectedLocale.lang + '.name']
                              "
                              @keyup="
                                messages['translations.' + selectedLocale.lang + '.name'] = ''
                              "
                              @change="handleChangeName"
                        ></v-text-field>
                    </v-col>
                </v-row>
                  
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-select
                            v-if="formModel"
                            v-model="formModel.news_category_id"
                            :items="categoryOptions"
                            :label="$t('MENU.NEWS_CATEGORIES')"
                            item-text="name"
                            item-value="id"
                            clearable="true"
                          />
                    </v-col>  
                </v-row>
                  
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <!--<v-textarea
                              v-if="formModel.translations[selectedLocale.lang]"
                              v-model="formModel.translations[selectedLocale.lang].text"
                              :rules="textRules"
                              :label="$t('FORMS.text')"
                              :id="dynamicID"
                              :error-messages="
                                messages['translations.' + selectedLocale.lang + '.text']
                              "
                              @keyup="
                                messages['translations.' + selectedLocale.lang + '.text'] = ''
                              "
                              @change="handleChangeName"
                        ></v-textarea>-->
                        <ckeditor
                            id="cke"
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="formModel.translations[selectedLocale.lang].text"
                            :error-messages="
                              messages['translations.' + selectedLocale.lang + '.text']
                            "
                            @input="
                              messages['translations.' + selectedLocale.lang + '.text'] = ''
                            "
                            :config="editorConfig"
                        ></ckeditor>
                        <small
                            v-if="messages['translations.' + selectedLocale.lang + '.text']"
                            style="color: red"
                        >
                            {{ messages["translations." + selectedLocale.lang + ".text"] }}
                        </small>
                    </v-col>
                </v-row>    
            </v-form>
        </v-card-text>

        <v-card-actions class="mb-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseModalForm">
                {{ $t("FORMS.cancel") }}
              </v-btn>
              <v-btn
                v-if="
                      (permissionCan('create') && !formModel.id) ||
                      permissionCan('update')
                "
                color="primary"
                elevation="2"
                @click="handleSaveModalForm"
              >
                {{ $t("FORMS.save") }}
              </v-btn>
        </v-card-actions>
      </v-card>  
      
      <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      
  </v-dialog>
</template>   
<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./CompetitionCalendar";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

const TRANSLATED_ATRIBUTES = ["name","text"];

export const initialFormData = () => ({
  id: 0,
  status: 1,
  date_of_competition: null,
  news_category_id: null,
  translations: {}
});

export default {
  name: "CompetitionCalendarModalForm",
  props: ["modalData", "permissions", "statuses", "endPoint", "dateOfCompetition"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,  
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      date_of_competition_date_picker: false,
      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 190) || this.$t("ERRORS.must_be_max_characters"),
      ],
      textules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 1000) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify",
        toolbar: [
          // [{ name: "basicstyles", items: ["Bold", "Italic"] }],
          ["Source"],
          ["U", "Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
          ["Link", "NumberedList", "BulletedList"],
        ],
        versionCheck: false,
      },
    };
  },
  computed: {
    ...mapGetters(["newsCategoryCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") +
            " " +
            this.$t("MENU.EVENT").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    categoryOptions() {
        let news_category_options = []; 
        this.newsCategoryCollection.forEach((item) => {
            if(item.news_category_type_id == 2) { //Sportágak
                /*news_category_options.push({
                    value: item.id,
                    text: item.name
                });*/
                news_category_options.push(item);            
            }          
        });          
        return news_category_options;
    }
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
                data.status = 1 * data.status;
                data.date_of_competition = data.date_of_competition.substring(0,10);
                this.formModel = Object.assign({}, data);
                this.setTranslatedAttributes();
            })
            .catch((error) => {
                console.log("Error!: ", error);
            })
            .finally(() => {
                this.loader = false;
            });
        } 
        else 
        {
          this.formModel = Object.assign({}, initialFormData());
          this.formModel.date_of_competition = this.dateOfCompetition;
          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchNewsCategory"]),
    setTranslatedAttributes() {
      if (this.formModel && this.formModel.translations) {
        if (!this.formModel.id) {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                // this.formModel.translations.push(language.lang);
                this.formModel.translations[language.lang] = {};
              }
              this.formModel.translations[language.lang][attribute] = null;
            });
            if (this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang]["attachments"] = [];
            }
          });
        } else {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
                this.formModel.translations[language.lang][attribute] = null;
              }
            });
          });
        }
      }
    },
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    }
  },
  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>    