<template>
  <div>
    <b-row>
      <b-col>
        <h4 class="text-primary">VERSENYNAPTÁR</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-row class="mb-1">
          <b-col cols="auto" class="mt-2">
            <label style="color: red" label-cols-sm="2" for="category">Sportág:</label>
          </b-col>
          <b-col>
            <b-form-select
              id="category"
              v-model="selectedCategory"
              :options="categoryOptions"
              @change="changeCategory"
            ></b-form-select>
          </b-col>
        </b-row>
 
        <b-calendar
          block
          v-model="selectedYMD"
          @context="onContext"
          locale="hu-HU"
          calendarLocale="hu-HU"
          selected-variant="warning"
          today-variant="primary"
          nav-button-variant="primary"
          :date-info-fn="dateClass"
          :start-weekday="1"
        ></b-calendar>
      </b-col>
      <b-col>
        <b-card :title="cardTitle" class="h-100">
          <b-card-text>             
            <div class="accordion" role="tablist">
              <b-card
                v-for="(value, key) in selectedEvents"
                :key="key"
                no-body
                class="mb-1"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'accordion-' + key"
                    variant="warning"
                    >{{ value.name }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + key.toString()"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text v-html="value.text">                       
                                           
                    </b-card-text>
                        <v-toolbar flat>
                            <v-btn 
                                v-if="permissionCan('update')" 
                                @click="handleEditCalendar(value)"
                            >
                                Szerkesztés
                            </v-btn>
                            <v-btn 
                                v-if="permissionCan('delete')" 
                                @click="deleteItem(value)"
                            >
                                Törlés
                            </v-btn>
                        </v-toolbar>   
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-card-text>
            
            <v-btn
                v-if="permissionCan('create') && selectedYMD" 
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="handleNewCalendar"
              >  
                {{ $t("MENU.NEW") }} {{ $t("MENU.EVENT") }}
              </v-btn>
        </b-card>
      </b-col>
    </b-row>
      
      <CompetitionCalendarModalForm
            :modalData="modalData"
            :permissions="permissions"
            :statuses="statuses"
            :endPoint="endPoint"
            :dateOfCompetition="selectedYMD"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
            @new="handleNew"
        ></CompetitionCalendarModalForm>
      
      <DeleteModalDialog
            :dialogDelete="dialogDelete"
            @closeDelete="handleCloseDelete"
            @deleteItemConfirm="handleDeleteItemConfirm"
      >
      </DeleteModalDialog>
      
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>  
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import CompetitionCalendarModalForm, { 
    initialFormData 
} from "./CompetitionCalendarModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
export const PERMISSION_TO = "competition_calendar.competition_calendar.";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export default {
  name: "CompetitionCalendar",
  mixins: [listModelsMixins],
  components: { CompetitionCalendarModalForm, DeleteModalDialog },
  data() {
    return {
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }).lang,
      events: [],
      routePath: "/cms/competitionCalendar/",
      competition_calendar: [],
      selectedYMD: "",
      selectedCategory: null,
      context: null,
      loader: false,
      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),
      dialogDelete: false,
      permissions: JSON.parse(localStorage.permissions),
      permissionTo: PERMISSION_TO
    };
  },
  computed: {
      ...mapGetters(["newsCategoryCollection", "statuses"]),
    selectedEvents() {      
      if (!this.selectedYMD) return [];
      return this.events.filter(
        (event) => event.date_of_competition == this.selectedYMD
      );              
    },

    cardTitle() {
      if (!this.selectedYMD) return "Nincs kiválasztva dátum";

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      let selectedYMD = new Date(this.selectedYMD);
      let categoryName =
        selectedYMD.toLocaleDateString("hu-HU", options) + " - ";
      if (!this.selectedCategory) {
        categoryName += "Összes sportági";
      } else {
        categoryName += this.categoryOptions.find(
          (item) => item.value == this.selectedCategory
        ).text;
      }
      return categoryName + " esemény";
    },
    categoryOptions() {
        let news_category_options = []; 
        news_category_options.push({ value: null, text: "Összes" });
        this.newsCategoryCollection.forEach((item) => {
            if(item.news_category_type_id == 2) { //Sportágak
                news_category_options.push({
                    value: item.id,
                    text: item.name
                });
            }          
        });          
        return news_category_options;
    },
    endPoint() {
      return "competition-calendar/admin/" + SiteService.getActiveSiteId() + "/competition-calendar/";
    },
  },
  mounted() {
      this.fetchNewsCategory();
      this.fetchCompetitionCalendar();
      var now = new Date();
      if(!this.selectedYMD) {
          this.selectedYMD = now.toISOString().substr(0, 10);
      }
      document.getElementsByClassName('b-calendar-grid-help')[0].style.display = 'none';
  },
  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
      ...mapActions(["fetchNewsCategory"]),
    onContext(ctx) {
      this.context = ctx;
    },

    dateClass(ymd) {
      return typeof this.events.find((item) => item.date_of_competition == ymd) !==
        "undefined"
        ? "table-warning"
        : "";
    },
    fetchCompetitionCalendar() {
        this.loader = true;
        return new Promise((resolve) => {
        ApiService.get(
            "competition-calendar/admin/" + SiteService.getActiveSiteId() + "/competition-calendar"
        ).then(({ data }) => {
          this.competition_calendar = [];  
          data.forEach((item) => {
              if (item.translations && typeof item.translations[this.selectedLocale] != 'undefined') {
                  let translations = item.translations[this.selectedLocale];
                  this.competition_calendar.push({
                    id: item.id,
                    date_of_competition: item.date_of_competition.substring(0,10),
                    news_category_id: item.news_category_id,
                    name: translations.name,
                    text: translations.text
                });
              }
          });
          resolve();
          this.events = this.competition_calendar;
        }).catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
      });
    },
    changeCategory() {
        if(this.selectedCategory) {
            this.events = [];
            this.competition_calendar.forEach((item) => { 
                if(item.news_category_id == this.selectedCategory) {
                    this.events.push(item);
                }
            });
        }
        else {
            this.events = this.competition_calendar;
        }
    },
    handleEditCalendar(data) {
        this.modalData.editedId = data.id;
        this.modalData.dialog = true;
    },
    handleNewCalendar() {
        this.modalData.editedId = null;
        this.modalData.dialog = true;
    },
    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedId = null;
      // let p1 = this.$route.path;
      // let p2 = this.routePath;
      // console.log(this.$route.path.replaceAll("/", ""), this.routePath.replaceAll("/", ""));
      if (
        this.$route.path.replaceAll("/", "") !=
        this.routePath.replaceAll("/", "")
      )
        this.$router.push(this.routePath);
    },
    handleSaveModalForm() {
        this.fetchModel();
        this.handleCloseModalForm();
    },
    fetchModel() {
        this.fetchCompetitionCalendar();
    }
  },
};
</script>

<style scoped>
/* .b-calendar-nav {
  background-color: #be1622 !important;
}
.btn.btn-outline-secondary {
  color: #fff !important;
} */
</style>


